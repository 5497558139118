<template>
  <div class="systemMesView">
    <pageHead title="系统消息" />
    <div
      infinite-scroll-throttle-delay="500"
      infinite-scroll-distance="50"
      v-infinite-scroll="loadMore"
      class="systemMesView_content">
      <!--      <topicCommunicate v-for="(item) in records" :key="item.id" :data="item" type='like'  class="likeView_content_item"/>-->
      <systemItem
        v-for="item in records"
        :key="item.id"
        :item="item"
        @provisions="provisions"
      ></systemItem>
      <van-popup v-model="showProvison" round :close-on-click-overlay="false">
        <provision @confirm="handleConfirm" />
      </van-popup>
      <no-more
        v-if="!params.hasNextPage && records.length > 0"
        style="margin-top: 14px"
      />
      <van-empty v-if="noData" description="暂无数据" />
    </div>
  </div>
</template>

<script>
import pageHead from "@/components/pageHead";
import systemItem from "./systemItem";
import NoMore from "@/components/uitls/NoMore";
import { getSystemMessage } from "@/api/message";
import provision from "./components/provision";

export default {
  components: {
    pageHead,
    systemItem,
    NoMore,
    provision,
  },
  data() {
    return {
      params: {
        type: 1,
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
      },
      records: [],
      noData: false,
      showProvison: this.$store.state.provisions,
    };
  },
  methods: {
    loadMore() {
      this.loadList();
    },
    loadList() {
      let listQueryParams = this.params;
      if (listQueryParams.hasNextPage) {
        getSystemMessage(this.params)
          .then((res) => {
            listQueryParams.hasNextPage = res.current < res.pages;
            listQueryParams.pageNum = listQueryParams.pageNum + 1;
            let records = this.records;
            res.records.forEach((item) => {
              let count = records.filter(
                (innerItem) => innerItem.id === item.id
              );
              if (count.length === 0) {
                records.push(item);
              }
            });
            this.records = records;
            if (this.records.length === 0) {
              this.noData = true;
            }
          })
          .catch(() => {
            this.records = [{ name: 1 }];
          });
      }
    },
    //法务条款确认事件
    async handleConfirm() {
      // await addLoginCount()
      this.showProvison = false;
    },
    provisions() {
      this.showProvison = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.systemMesView {
  height: 100%;
  display: flex;
  flex-direction: column;

  &_content {
    flex: 1;
    overflow-y: auto;
    padding: 0 29px;
    background-color: #ffffff;

    &_item {
      margin-top: 10px;
    }
  }
  .van-popup {
    width: 80%;
    padding: 20px;
    height: 80%;
  }
}
</style>
