<template>
  <div class="system_item">
    <p ref="title" class="title" v-html="handleTextContent(item.content)"></p>
    <div class="content" @click="jumpCircle(item)" v-if="item.relationInfo.articleId">
      <img v-if="item.relationInfo.image" :src="item.relationInfo.image" alt="" />
      <p class="text">
        {{ item.relationInfo.title }}
        <!-- Hi，小氪爱们，你们好啊~最近我读最近我读了一本书《钢铁是怎样炼成的... -->
      </p>
    </div>
    <span v-if="item.createTime" class="time">{{ item.createTime }}</span>
  </div>
</template>

<script>
import {handleTextContent} from "@/utils/utils";
import {detail as getArticleDetail} from "@/api/article";

export default {
  name: "systemItem",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    handleTextContent(this.item.content);
    // const res = handleTextContent(this.item.content);
    // console.log("res");
    // console.log(res);
  },
  mounted() {
    let vm = this;
    if (vm.$refs.title.querySelector(".provisionsText")) {
      vm.$refs.title
        .querySelector(".provisionsText")
        .addEventListener("click", () => {
          vm.$emit("provisions");
        });
    }
  },
  methods: {
    handleTextContent,
    // 跳转圈子
    jumpCircle(item) {
      if (item.code === 15) {
        this.getArticleDetail(item)
      }
    },
    /**
     * todo 根据id获取帖子详情
     */
    async getArticleDetail(item) {
      if (item.relationInfo && item.relationInfo.articleId) {
        try {
          await getArticleDetail({articleId: item.relationInfo.articleId});
          await this.$router.push({
            path: '/app/home/topicDetail',
            query: {
              id: item.relationInfo.articleId,
            }
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .circleText {
  font-size: 14px;
  color: #fe5800;
  line-height: 18px;
}

::v-deep .provisionsText {
  font-size: 14px;
  color: #0a76f4;
  line-height: 18px;
}

.system_item {
  display: flex;
  flex-direction: column;
  padding: 17px 0 16px;
  border-bottom: 1px solid #f4f4f4;

  .title {
    margin: 0;
    font-size: 14px;
    color: #000000;
    line-height: 21px;
  }

  .content {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    padding: 10px 17px 9px 13px;
    background: #f7f7f7;
    border-radius: 4px;
    align-items: center;

    img {
      width: 52px;
      height: 52px;
      border-radius: 4px;
      margin-right: 10px;
    }

    .text {
      margin: 0;
      font-size: 12px;
      color: #333333;
      line-height: 20px;
    }
  }

  .time {
    margin-top: 8px;
    font-size: 12px;
    color: #6d7278;
    line-height: 18px;
  }
}
</style>
